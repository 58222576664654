const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://z10a07pxif.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://w5bsza9z49.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.2',
    HOSTNAME: 'https://company.demo.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.demo.forwoodsafety.com',
    WEBSOCKET: 'wss://4ttf2gjoi3.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;